import React from 'react';

const AppIcon = ( props ) => {

    return (
        <div className={props.className}>
            <div className="relative box-border w-full h-full app-outline border-gray-400 rounded-lg overflow-hidden">
                <img className="absolute" src={props.app ? props.app.Icon : ""} />
            </div>
            
        </div>
    )

}

export default AppIcon;