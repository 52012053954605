import React from 'react';
import SEO from '../SEO';
import Header from './header'
import Footer from './footer';

const Layout = ( props ) => {
    return (
        <div className="background">
            <SEO />
            <Header 
                apps={props.apps}
                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut bibendum metus sit amet bibendum aliquet. Mauris mattis id urna sit amet congue."/>
            <div className='content text-gray-900'>{props.children}</div>
            <Footer />
        </div>
        
    )

}

export default Layout;