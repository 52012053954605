import React from 'react';
import Heart from '../../assets/heart.svg'
import { Link } from "gatsby"


const Header = ( props ) => {

    /*
    Use standard title unless apps prop is given in which case do app1 vs app2
    */

    const standardTitle = () => {
        return (
            <h1 className="mt-6 mb-2 text-white font-montserrat font-bold text-4xl">
                Welcome to Habit App Compare
            </h1>
        )
    }

    const comparisonTitle = () => {
        return (
            <div className="m-8 pickerLayout align-center text-white font-montserrat font-bold text-4xl">
                <p className="flex-1 w-full max-w-sm self-center">  
                    {props.apps[0].App}
                </p>

                <p className="w-16 font-montserrat font-light text-4xl self-center text-center">vs</p>

                <p className="flex-1 w-full max-w-sm self-center">  
                    {props.apps[1].App}
                </p>
            </div>
        )
    }

    return (
        <div className= "flex-col justify-center items-center text-center p-6">
            <h1><Link to="/" className="text-white font-body font-light text-2xl">Habit App Compare</Link></h1>
            <p className="text-white font-body font-thin text-sm">
                Made with
                <span className="mx-1 w-4 h-4 inline-block"><Heart className="text-red-500 fill-current adjustTop relative"/></span>
                by SnapHabit</p>

            {props.apps ? comparisonTitle() : standardTitle()}
            {/* <p className="max-w-screen-sm m-auto text-white font-montserrat font-light text-sm">{props.desc}</p> */}
        </div>
    )

}

export default Header;