import React from 'react';
import Mail from '../../assets/email.svg'
import Twitter from '../../assets/twitter.svg'

const Footer = ( props ) => {

    return (
        <div className="m-auto w-11/12 max-w-3xl min-w-5/6 flex-col pt-4 pb-16 font-body text-s text-white">
            <div className="mt-6 flex flex-wrap justify-between align-middle">
                <div className="my-2 mx-3 sm:ml-0 w-full sm:w-auto opacity-75">© Snaphabit 2020</div>
                <div className="flex-1 w-full sm:w-auto"></div>
                <div className="my-2 mx-3 w-full sm:w-auto"><a href="https://snaphabit.app/privacy">Privacy Policy</a></div>
                <div className="my-2 mx-3 w-full sm:w-auto"><a href="https://snaphabit.app/contact">Contact Us</a></div>
                <div className="my-2 mx-3 w-full sm:w-auto"><a href="https://snaphabit.app/about">About</a></div>
                <div className="my-2 mx-3 w-full sm:w-auto"><a href="https://docs.google.com/forms/d/e/1FAIpQLSe9HZiU-wnTyFPg8esPxsj5gWJJBl9MVnX7xZQ0K6N-vh-Qig/viewform?usp=sf_link">Request an Edit</a></div>
                <div className="my-6 sm:my-2 mr-3 ml-2 sm:mr-0 sm:ml-3 w-full sm:w-auto">
                    <a className="py-2 px-4 bg-white rounded-full text-blue-700 font-semibold" 
                        href="https://5cgdk.app.link/6uLVR26qI8">Get SnapHabit
                    </a>
                </div>      
            </div>
            <div className="flex mt-4 mx-4 sm:mx-0 sm:mt-2">
                <a className = "w-5 h-5 mr-2" href="mailto:info@snaphabit.com"><Mail /></a>
                <a className = "w-5 h-5 ml-1" href="https://twitter.com/SnapHabitApp"><Twitter /></a>
            </div>
            
        </div>
    )

}

export default Footer;

/*
<button className="m-1 py-2 px-4 bg-white text-blue-600 rounded-full">Request an edit</button>
                <button className="m-1 py-2 px-4 border-white border rounded-full">Get SnapHabit</button>
                */